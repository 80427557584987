export async function getPermissions() {
  if (navigator?.permissions) {
    return (
      navigator.permissions
        ?.query({ name: 'microphone' })
        .then((result) => result.state)
        .catch((err) => {
          return 'prompt';
        })
    );
  }
  return 'prompt';
}
