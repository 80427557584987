import { Controller } from "@hotwired/stimulus"

import { AudioDevices } from "../audio_devices"
import { AudioRecorder } from "../audio_recorder"

import { recordDetectionChannel } from "../channels/record_detection_channel"


// Connects to data-controller="record-detection"
export default class extends Controller {
  static values = {
    id: Number,
    recordingTimeInSeconds: { type: Number, default: 60 },
    detectionPeriodInSeconds: { type: Number, default: 10 },
  }

  static targets = [
    "noAccessBlock", "controlsBlock", "detectionsBlock",
    "detectionActivityBlock", "recordButton", "stopButton",
  ]

  #status = 'initial'
  #recorder = null

  #recordingTimeout = null
  #detectionInterval  = null

  async connect() {
    console.log(`record-detection connected`)
    await this.#setup();
  }

  async #setup() {
    const audioDevices = new AudioDevices()
    await audioDevices.promptAudioInputs()

    if (!audioDevices.denied) {
      console.log('access ok')
      this.wait()
    }
    else {
      console.log('access denied')
      this.denied()
    }
  }

  wait() {
    if (this.#status == 'initial') {
      this.#status = 'waiting'

      this.#hideNoAccessWarning()
      this.#hideDetectionActivity()
      this.#showRecordControls()
    }
    else if (this.#status == 'recording') {
      this.#status = 'waiting'
      this.#stopRecorder()

      if (this.#recordingTimeout) {
        clearTimeout(this.#recordingTimeout)
      }
      if (this.#detectionInterval) {
        clearInterval(this.#detectionInterval)
      }

      this.#hideDetectionActivity()
      this.#showRecordControls()
    }
    else {
      logger.info(`status: ${this.#status}`)
    }
  }

  record() {
    if (this.#status == 'waiting') {
      this.#status = 'recording'

      this.#startRecorder()

      this.#recordingTimeout = setTimeout(async () => {
        this.wait()
      }, this.recordingTimeInSecondsValue * 1000)

      this.#detectionInterval = setInterval(async () => {
        this.#sendData()
      }, this.detectionPeriodInSecondsValue * 1000)

      this.#showDetectionActivity()
      this.#showWaitControls()
    }
    else {
      logger.info(`status: ${this.#status}`)
    }
  }

  denied() {
    this.#status = 'denied'

    this.#hideAllControls()
    this.#hideDetectionActivity()
    this.#showNoAccessWarning()
  }

  async #startRecorder() {
    if (!this.#recorder) {
      this.#recorder = new AudioRecorder()
      this.#recorder.start()
    }
  }

  async #stopRecorder() {
    if (this.#recorder) {
      this.#recorder.stop()
      this.#sendData()
      this.#recorder = null
    }
  }

  async #sendData() {
    if (this.#status == 'recording') {
      this.#recorder.stop()
    }

    const blob = await this.#recorder.save()
    if (blob) {
      const buffer = await blob.arrayBuffer()
      const bytes = new Uint8Array(buffer)
      const binary = String.fromCharCode.apply(null, bytes)
      const base64encoded = btoa(binary)
      recordDetectionChannel.push(this.idValue, { data: base64encoded })
    }

    if (this.#status == 'recording') {
      this.#recorder.start()
    }
  }

  #hideNoAccessWarning() {
    this.noAccessBlockTarget.classList.add("hidden")
  }

  #showNoAccessWarning() {
    this.noAccessBlockTarget.classList.remove("hidden")
  }

  #showRecordControls() {
    this.controlsBlockTarget.classList.remove("hidden")

    this.recordButtonTarget.classList.remove("hidden")
    this.recordButtonTarget.disabled = false

    this.stopButtonTarget.classList.add("hidden")
    this.stopButtonTarget.disabled = true
  }

  #showWaitControls() {
    this.controlsBlockTarget.classList.remove("hidden")

    this.recordButtonTarget.classList.add("hidden")
    this.recordButtonTarget.disabled = true

    this.stopButtonTarget.classList.remove("hidden")
    this.stopButtonTarget.disabled = false
  }

  #hideAllControls() {
    this.controlsBlockTarget.classList.add("hidden")

    this.recordButtonTarget.classList.add("hidden")
    this.recordButtonTarget.disabled = true

    this.stopButtonTarget.classList.add("hidden")
    this.stopButtonTarget.disabled = true
  }

  #showDetectionActivity() {
    this.detectionActivityBlockTarget.classList.remove("hidden")
  }

  #hideDetectionActivity() {
    this.detectionActivityBlockTarget.classList.add("hidden")
  }

  async disconnect() {
    console.log(`record-detection disconnected`)
  }
}
