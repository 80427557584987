import { Controller } from "@hotwired/stimulus"

import flatpickr from "flatpickr"
import { DateTime } from "luxon"

// Connects to data-controller="datetime-range-picker"
export default class extends Controller {
  static targets = ["main", "start", "end", "form"]

  connect() {
  }

  initialize() {
    const start = DateTime.fromISO(this.startTarget.value)
    const end   = DateTime.fromISO(this.endTarget.value)

    this.flatpicker = flatpickr(this.mainTarget, {
      mode: 'range',
      onChange: (selectedDates, dateStr, instance) => {
        this.datesChanged(selectedDates)
      },
      defaultDate: [start.toJSDate(), end.toJSDate()],
      locale: {
        firstDayOfWeek: 1,
      }
    })
  }

  datesChanged(newDates) {
    if (newDates.length == 2) {
      const start = newDates[0]
      const end   = newDates[1]

      this.startTarget.value = DateTime.fromJSDate(start).toISODate()
      this.endTarget.value   = DateTime.fromJSDate(end).toISODate()

      if (this.hasFormTarget) {
        this.formTarget.submit()
      }
    }
  }
}
