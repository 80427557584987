import { getPermissions } from "./audio_permissions";
export class AudioRecorder {

  #mimeType = "audio/ogg;codecs=opus"
  #chunks = []

  constructor() {
    console.log(`AudioRecorder: init`)
  }

  async start() {
    console.log(`AudioRecorder: start`)

    this.#chunks = []

    if ((await getPermissions()) === "denied") {
      throw new Error("Microphone permission denied.");
    }

    let audio = {
      sampleRate: 48000,
    };

    let stream = await navigator.mediaDevices.getUserMedia({ audio });

    this.recorder = new MediaRecorder(stream, {
      mimeType: this.#mimeType,
    });

    this.recorder.ondataavailable = (event) => {
      console.log(event.data)
      if (event.data && event.data.size > 0) {
        this.#chunks.push(event.data)
      }
    };

    this.recorder.start(1000);
  }

  stop() {
    console.log(`AudioRecorder: stop`)
    this.recorder.stop()
  }

  async save() {
    console.log(`AudioRecorder: save`)

    await new Promise(res => setTimeout(res, 1000))

    const blob = new Blob(this.#chunks, { type: this.#mimeType })
    this.#chunks = []

    return blob
  }
}
