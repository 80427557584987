// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import "./channels"

import Alpine from "alpinejs"
import 'alpine-turbo-drive-adapter'
import persist from "@alpinejs/persist"

Alpine.plugin(persist)
window.Alpine = Alpine
Alpine.start()
