import consumer from "./consumer"

export const recordDetectionChannel = consumer.subscriptions.create("RecordDetectionChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log(`RecordDetectionChannel: connected`)
    this.install()
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log(`RecordDetectionChannel: disconnected`)
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log(`RecordDetectionChannel: received: ${data}`)
  },

  install() {
  },

  push(id, { data }) {
    console.log(`push: id: ${id}; data: ${data}`)
    this.perform('detect', { id, length: data.byteLength, data })
  }
});
